var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($, Drupal, prodcat) {
  var _isSoldOut = function (sku) {
    return !!sku.isSoldOut;
  };
  var _isShoppable = function (sku) {
    return !!sku.isShoppable;
  };

  Drupal.behaviors.productBriefShadesV1 = {
    isMobile: false,
    nodes: {},
    slickSettings: {
      autoplay: false,
      slidesToShow: 7,
      slidesToScroll: 7,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>'
    },

    attach: function (context) {
      var self = this;

      // Helper function to activate whatever shade to selected
      self.getDom();

      // Generate the shade carousel
      self.nodes.$sliders.each(function () {
        if (!$(this).hasClass('slick-initialized')) {
          self.initSlider($(this));
        }
      });

      self.checkIfMobile();
      Unison.on('change', function () {
        self.checkIfMobile();
        self.reinitSliders();
      });
    },
    getDom: function (context) {
      var self = this;

      self.nodes.$regimenFinderProducts = $('.js-foundation-finder-result-page', context);
      self.nodes.$sliders = $('.product-brief-shades-v1:not(.slick-initialized)', context);
    },
    activateShade: function ($skuListItems, skuBaseId) {
      // Helper function to activate whatever shade to selected
      // Deactivate all skus
      $skuListItems.removeClass('shade-active');
      // Activate the selected sku
      $skuListItems.filter('[data-sku-base-id=' + skuBaseId + ']').addClass('shade-active');
    },
    initSlider: function ($template) {
      var self = this;
      var $skuListItems = $('.js-product-brief-shade', $template);
      var skuBaseId = $template.closest('.js-product').data('sku-base-id');

      $skuListItems.each(function () {
        var $shade = $(this);
        var skuId = $shade.data('sku-base-id');
        var sku;
        var classes = [];

        if (skuId) {
          sku = prodcat.data.getSku(skuId);
          if (!sku) {
            return;
          }
        } else {
          return;
        }

        if (_isSoldOut(sku)) {
          classes.push('inv-status--sold-out');
        }
        if (!_isShoppable(sku)) {
          classes.push('inv-status--not-shoppable');
        }

        if (classes.length > 0) {
          $shade.addClass(classes.join(' '));
        }
      });

      // set initial active state
      self.activateShade($skuListItems, skuBaseId);

      $skuListItems.once().on('click', function (e) {
        var $item = $(this);
        var skuBaseId = $item.data('sku-base-id');

        e.preventDefault();
        self.activateShade($skuListItems, skuBaseId);
        $item.closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
      });

      // Checking if Regimen Finder to prevent slick settings duplication.
      if (self.nodes.$regimenFinderProducts.length === 0) {
        $template.slick(self.slickSettings);
      }
    },
    reinitSliders: function () {
      var self = this;

      self.nodes.$sliders.each(function () {
        var $this = $(this);

        if ($this.hasClass('slick-initialized')) {
          $this.slick('reinit');
        }
      });
    },
    checkIfMobile: function () {
      var self = this;
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();

      self.isMobile = parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
    }
  };
})(jQuery, window.Drupal || {}, prodcat);
